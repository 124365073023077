<template>
    <div>
  <div v-if="loader">
    Loading...
  </div>
  <div v-if="!loader"></div>
  <div class="singup_comman_banner">
                <div class="comman_banner">
                    <div class="container">
                        <div class="banner_logo">
                            <img src="./casuals_couriers/images/simpo_logo.svg" alt="Simpo" title="Simpo">
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="singup_page">
                        <div class="singup_title">
                            <h4>כניסה למערכת</h4>
                            <h5>!Simpo ברוכים הבאים למערכת  </h5>
                            <h5>על מנת להכניס חבילות בפעם הראשונה יש למלא את הפרטים הבאים באופן חד-פעמי</h5>
                        </div>
                        <form @submit="login" class="singup_form" >
                            <p class="error_text" >{{errs}}</p>
                            <div class="form-group">
                                <label>שם חברת המשלוחים</label>
                                <input type="text" class="form-control"  v-model="company_name">
                            </div>
                            <div class="form-group">
                                <label> SMS טלפון נייד לקבלת </label>
                                <input type="phone" class="form-control" v-model="phone">
                            </div>
                            <div class="form-group">
                                <input type="submit" class="comman_anchor" value="אישור">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
</div>
</template>

<script>
import BackendModel from "../../models/BackendModel";
// import PointGlobalHeader from "./PointGlobalHeader";
import("./casuals_couriers/css/style.css");
export default {
  name: "CasualCouriersLogin",
  components: {
    // PointGlobalHeader,
  },
  data() {
    return {
      loader: false,
      phone: "",
      company_name: "",
      errs: "",
      global_err: "",
    };
  },
  async mounted() {
    const backendModel = new BackendModel();
    this.loader = true;
    this.unit_id = this.$route.query.unit_id
    console.log("in")
    let casual_courier_session_id = backendModel.getCasualCourierId();
    let authRes = await backendModel.CasualCourierRequest(
      "/Api/service/backoffice/casual_courier_auth",
      { casual_courier_session_id }
    );
    if (typeof authRes == "undefined") {
      this.global_err = "Backend is down?";
    } else if (authRes.data.res == "ERROR") {
      this.global_err = authRes.data.e;
    } else if (authRes.data.res == "OK") {
      await this.$router.push({ path: "/casual_couriers/casual_couriers_add_package", query:{"unit_id": this.unit_id} });
    }else if (authRes.data.res.casual_courier_session_id == "") {
        this.loader = false;
    }
    this.loader = false;
    this.errs = "";
  },
  methods: {
    async login(e) {
      e.preventDefault();
      const backendModel = new BackendModel();
      let res = await backendModel.CasualCourierRequest(
        "/Api/service/backoffice/casual_courier_login_step_one",
        { company_name: this.company_name, phone: this.phone }
      );
      if (res.data.res == "NOK" && res.data.err == "less_data") {
        this.errs = "אופס, שכחת משהו";
      }
      if (res.data.res == "NOK") {
        this.errs = "שגיאה";
      }
      if (res.data.res == "OK") {
        let session_id = res.data.session_id;
        let CookieDate = new Date();
        CookieDate.setFullYear(CookieDate.getFullYear() + 10);
        document.cookie =
          "casual_courier_session_id =" +
          session_id +
          "; expires=" +
          CookieDate.toUTCString() +
          ";";
        await this.$router.push({ path: "/casual_couriers/sms_step", query:{"unit_id": this.unit_id} });
        this.loader = false;
      }
    },
  },
};
</script>

<style>
@import "./casuals_couriers/css/style.css";
</style>
